import p84 from "./84";
import p85 from "./85";
import p86 from "./86";
import p87 from "./87";
import p88 from "./88";
import p89 from "./89";
import p90 from "./90";
import p91 from "./91";
import p92 from "./92";
import p93 from "./93";
import p94 from "./94";
import p95 from "./95";

const pageQuestions = {
  8: p84,
  9: p85,
  10: p86,
  11: p87,
  12: p88,
  13: p89,
  14: p90,
  15: p91,
  16: p92,
  17: p93,
  18: p94,
  19: p95,
  // 20: p20,
  // 21: p21,
  // 22: p22,
  // 23: p23,
  // 24: p24,
  // 25: p25,
  // 26: p26,
  // 27: p27,
  // 28: p28,
  // 29: p29,
  // 30: p30,
  // 31: p31,
  // 32: p32,
  // 33: p33,
  // 34: p34,
  // 35: p35,
  // 36: p36,
  // 37: p37,
  // 38: p38,
  // 39: p39,
  // 40: p40,
  // 41: p41,
  // 42: p42,
  // 43: p43,
  // 44: p44,
  // 45: p45,
  // 46: p46,
  // 47: p47,
  // 48: p48,
  // 49: p49,
  // 50: p50,
  // 51: p51,
  // 52: p52,
  // 53: p53,
  // 54: p54,
  // 55: p55,
  // 56: p56,
  // 57: p57,
  // 58: p58,
  // 59: p59,
  // 60: p60,
  // 61: p61,
  // 62: p62,
  // 63: p63,
  // 64: p64,
  // 65: p65,
  // 66: p66,
  // 67: p67,
  // 68: p68,
  // 69: p69,
  // 70: p70,
  // 71: p71,
  // 72: p72,
  // 73: p73,
  // 74: p74,
  // 75: p75,
  // 76: p76,
  // 77: p77,
  // 78: p78,
  // 79: p79,
  // 80: p80,
  // 81: p81,
  // 82: p82,
  // 83: p83,
  // 84: p84,
  // 85: p85,
  // 86: p86,
  // 87: p87,
  // 88: p88,
  // 89: p89,
  // 90: p90,
  // 91: p91,
  // 92: p92,
  // 93: p93,
  // 94: p94,
  // 95: p95,
  // 96: p96,
  // 97: p97,
  // 98: p98,
  // 99: p99,
  // 100: p100,
  // 101: p101,
  // 102: p102,
  // 103: p103,
  // 104: p104,
  // 105: p105,
  // 106: p106,
  // 107: p107,
  // 108: p108,
  // 109: p109,
  // 110: p110,
  // 111: p111,
  // 112: p112,
  // 113: p113,
  // 114: p114,
  // 115: p115,
  // 116: p116,
  // 117: p117,
  // 118: p118,
  // 119: p119,
  // 120: p120,
  // 121: p121,
  // 122: p122,
  // 123: p123,
  // 124: p124,
  // 125: p125,
  // 126: p126,
  // 127: p127,
  // 128: p128,
  // 129: p129,
  // 130: p130,
  // 131: p131,
};

export default pageQuestions;
